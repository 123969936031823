@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
html, body {
	margin: 0;
	padding: 0;
	
	background-color: white !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#nprogress .bar {
	height     : 4px;
	z-index    : 1500;
	background : #99BC12;
}


